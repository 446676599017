<template>
  <img
    :src="props.src"
    :style="{ opacity: imageIsLoaded ? 1 : 0 }"
    class="image"
    @load="imageIsLoaded = true"
    @dragstart.prevent
  />
</template>

<script setup lang="ts">
const props = defineProps<{
  src: string;
}>();

const imageIsLoaded = ref<boolean>(false);
</script>

<style lang="scss" scoped>
.image {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 65%;
  transform: translate(-50%, -56%);
  transition: 0.25s;
}
</style>
