<template>
  <span class="stattrack">ST</span>
</template>

<style lang="scss" scoped>
.stattrack {
  color: $yellow;
  margin-right: 2px;
}
</style>
